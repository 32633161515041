/* 3. content */

.page-content {
    width: 970px;
    position: relative;
    right: -60%;
    margin-top: 50px;
    transform: translateX(-50%);

    @media (max-width:1699px) {
        right: -62%
    }

    @media (max-width:1599px) {
        right: -63%
    }

    @media (max-width:1399px) {
        width: 800px;
    }

    @media (max-width:1199px) {
        width: 600px;
        right: -65%;
    }

    @media (max-width:991px) {
        width: 100%;
        position: unset;
        transform: unset;
        margin-top: 0;
        padding: 0 15px;
    }


}




/*3.1 about-section */

.about-section {
    background: $white;
    border-radius: 20px;
    padding: 85px 75px 30px;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;

    @media (max-width:1399px) {
        padding: 50px 35px 40px;
    }

    @media (max-width:575px) {
        padding: 40px 10px 10px;
    }

    .image {
        border-radius: 190px;
        width: 380px;
        height: 380px;
        margin: 0 auto;
        padding: 25px;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
        margin-top: -25px;
        overflow: hidden;

        @media (max-width:1199px) {
            width: 300px;
            height: 300px;
            margin-top: 0;
        }

        @media (max-width:575px) {
            width: 250px;
            height: 250px;
        }

        .image-inner {
            border-radius: 190px;
            overflow: hidden;
        }


        img {
            border-radius: 190px;
            -webkit-animation: kenburn 20s 1.2s alternate infinite;
            -animation: kenburn 20s 1.2s alternate infinite;

            @media (max-width:575px) {
                padding: 10px;
            }
        }

        .white-img {
            transition: all .3s ease-in-out;
        }

        .dark-img {
            display: none;
            transition: all .3s ease-in-out;
        }
    }

    .content {
        max-width: 813px;
        margin: 0 auto;
        text-align: center;
        padding: 45px 0 60px;

        @media (max-width:575px) {
            padding-bottom: 40px;
        }

        h2 {
            color: $heading-color;
            font-family: $heading-font;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 7px;

            @media (max-width:575px) {
                font-size: 25px;
            }
        }

        h3 {
            color: $heading-color;
            font-family: $heading-font;
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 20px;

            @media (max-width:1199px) {
                font-size: 35px;
            }

        }

        p {
            color: $heading-color;
            font-family: $base-font;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 0;

            @media (max-width:1199px) {
                font-size: 18px;
            }

        }

    }

    .left-text,
    .right-text {
        position: absolute;
        left: 5px;
        top: 10px;
        z-index: -1;

        h2 {
            font-family: $heading-font;
            font-size: 100px;
            font-weight: 900;
            line-height: 120px;
            color: #DFDFDF;
            margin-bottom: 0;

            @media (max-width:1199px) {
                font-size: 70px;
                line-height: 90px;
            }

            @media (max-width:767px) {
                font-size: 50px;
                line-height: 70px;
            }
        }

    }

    .right-text {
        left: auto;
        right: 5px;
        top: 360px;

        @media (max-width:1199px) {
            top: 300px;
        }

        @media (max-width:767px) {
            top: 250px;
        }
    }

    .text-outline {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: currentColor;
        -webkit-text-stroke-width: 1px;
        overflow: hidden;
    }

    .text-outline-hover {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 0;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            content: attr(data-text);
            transition: max-width .8s cubic-bezier(.22, .61, .36, 1);
            -webkit-text-fill-color: currentColor;
            animation: drift 9s 2s linear infinite both;
        }

    }



}

@-webkit-keyframes kenburn {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        -webkit-transform: scale3d(1.3, 1.3, 1.3);
        transform: scale3d(1.3, 1.3, 1.3);
    }
}

@keyframes kenburn {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        -webkit-transform: scale3d(1.3, 1.3, 1.3);
        transform: scale3d(1.3, 1.3, 1.3);
    }
}

@keyframes drift {
    from {
        max-width: 0;
    }

    to {
        max-width: 100%;
    }
}

.progras-card {
    margin-bottom: 30px;


    .percent {
        position: relative;
    }

    svg {
        position: relative;
        width: 136px;
        height: 136px;
        transform: rotate(90deg);
    }

    svg circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: #EAEAEA;
        stroke-width: 14;
        stroke-linecap: round;
    }

    svg circle:last-of-type {
        stroke-dasharray: 380px;
        stroke-dashoffset: calc(380px - (380px * var(--percent)) / 100);
        stroke: $small-black-s2;
    }

    .number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .number h3 {
        color: $heading-color;
        text-align: center;
        font-family: $heading-font;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 0;
    }


    .title h2 {
        color: $heading-color;
        text-align: center;
        font-family: $heading-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0;
        margin-top: 15px;

        @media (max-width:1399px) {
            font-size: 19px;
        }

    }

}


/*3.2 service-section */
.service-section {
    background: $white;
    border-radius: 20px;
    padding: 80px 45px 50px;
    margin-bottom: 30px;

    @media (max-width:1399px) {
        padding: 80px 20px 50px;
    }

    @media (max-width:575px) {
        padding: 60px 5px 30px;
    }
}

.service_item {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #DADADA;
    padding: 30px;
    margin-bottom: 30px;

    @media (max-width:1399px) {
        padding: 20px;
    }

    @media (max-width:991px) {
        display: block;
        text-align: center;
        padding: 30px;
    }

    @media (max-width:575px) {
        padding: 30px 20px;
    }

    .icon {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EDEDED;

        @media (max-width:991px) {
            margin: 0 auto;
            margin-bottom: 15px;
        }

        .white-img {
            display: block;
            transition: all .3s ease-in-out;
        }

        .black-img {
            display: none;
            transition: all .3s ease-in-out;
        }
    }

    .content {
        padding-left: 24px;
        width: calc(100% - 80px);

        @media (max-width:991px) {
            width: 100%;
            padding-left: 0;
        }

        h2 {
            color: $heading-color;
            font-family: $heading-font;
            font-size: 25px;
            font-weight: 700;
            line-height: 50px;
            margin-bottom: 0;
            cursor: pointer;


        }

        p {
            color: #3C3C3C;
            font-family: $heading-font;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 0;

            @media (max-width:575px) {
                font-size: 17px;
                line-height: 25px;
            }
        }
    }
}


/*3.3 portfolio-section */
.portfolio-section {
    background: $white;
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 80px 35px 50px;

    @media (max-width:1399px) {
        padding: 80px 20px 50px;
    }

    @media (max-width:575px) {
        padding: 60px 5px 30px;
    }
}

.portfolio-category {
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;

    @media (max-width:575px) {
        max-width: 100%;
    }

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:575px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        li {
            button {
                color: #252525;
                font-family: $heading-font;
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
                padding: 5px 30px;
                border-radius: 20px;
                background: transparent;
                border: 1px solid #D7D7D7;
                transition: all .3s ease-in-out;
                margin-bottom: 10px;

                @media (max-width:575px) {
                    margin: 0px 10px 10px 10px;
                }

                &.active {
                    border: 1px solid transparent;
                    background: #383838;
                    color: $white;
                }
            }

        }
    }
}

.portfolio_card {
    margin-bottom: 30px;

    .image {
        border-radius: 20px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;

        span {
            position: absolute;
            top: -20%;
            left: 20px;
            border-radius: 20px;
            background: rgb(153, 153, 153, 0.5);
            color: $white;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 35px;
            padding: 3px 27px;
            transition: all .5s ease-in-out;
            opacity: 0;
            visibility: hidden;
        }

        img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            transition: all 1s;
        }
    }

    &:hover {
        .image {
            span {
                top: 20px;
                opacity: 1;
                visibility: visible;
            }

            img {
                transform: scale(1.1);
            }
        }
    }

    h2 {
        color: $heading-color;
        font-family: $heading-font;
        font-size: 25px;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: -5px;
        margin-top: 8px;
        cursor: pointer;
    }
}

/*3.4 testimonial-section */
.testimonial-section {
    background: $white;
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 80px 0px;

    @media (max-width:575px) {
        padding: 60px 0px 30px;
    }
}

.slick-slider {
    margin: 0 auto;
    max-width: 800px;
}


.testimonial-card {
    border-radius: 20px;
    border: 1px solid #A8A8A8;
    padding: 50px 80px;
    position: relative;
    z-index: 1;

    @media (max-width:1199px) {
        padding: 30px 30px;
    }

    @media (max-width:575px) {
        padding: 30px 20px;
    }

    .top-content {
        display: flex;
        align-items: center;

        @media (max-width:575px) {
            display: block;
        }

        .image {
            width: 120px;
            height: 120px;
            border-radius: 320px;
            border: 2px solid #DAD2BD;
            padding: 10px;

            @media (max-width:575px) {
                width: 100px;
                height: 100px;
            }

            img {
                border-radius: 320px;
                width: 100%;
            }
        }

        .text {
            padding-left: 20px;

            @media (max-width:575px) {
                padding-top: 20px;
                padding-left: 0;
            }

            h2 {
                color: #262626;
                font-size: 40px;
                font-weight: 600;
                line-height: 60px;
                margin-bottom: 0;

                @media (max-width:575px) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }

            span {
                color: #2F2F2F;
                font-size: 22px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width:575px) {
                    font-size: 17px;
                }
            }
        }
    }

    h3 {
        color: #2F2F2F;
        font-size: 22px;
        font-weight: 400;
        line-height: 38px;
        margin-bottom: 0;
        margin-top: 20px;

        @media (max-width:575px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .icon {
        width: 92px;
        height: 92px;
        position: absolute;
        top: 25px;
        right: 60px;
        z-index: -1;

        @media (max-width:575px) {
            width: 70px;
            height: 70px;
            right: 20px;
        }
    }
}


.testimonial-wrap {
    position: relative;

    .slick-slide {
        text-align: center;

        .testimonial-card {
            text-align: left;
        }

    }

    .slick-slider {
        .slick-slide {
            padding: 15px;
        }
    }

    // slider controls
    .slick-prev,
    .slick-next {
        background-color: $white;
        width: 45px;
        height: 45px;
        line-height: 41px;
        z-index: 10;
        @include rounded-border(5px);
        transition: all .3s;
        border: 2px solid #D9D9D9;
        color: $theme-primary-color;
        color: $text-color;

        &:hover {
            background-color: $theme-primary-color;
            color: $white;
        }

        @media(max-width:575px) {
            display: none !important;
        }
    }

    .slick-prev {
        background: $white;
        left: -8px;

        &:before {
            font-family: "themify";
            content: "\e629";
            opacity: 1;
            line-height: unset;
            color: $text-color;
        }
    }

    .slick-next {
        left: auto;
        right: -8px;

        @media(max-width:575px) {
            display: none;
        }

        &:before {
            font-family: "themify";
            content: "\e628";
            opacity: 1;
            line-height: unset;
            color: $text-color;
        }
    }

    .slick-dots {
        display: none !important;

        @media(max-width:575px) {
            display: block !important;
        }

        li button:before {
            color: $theme-primary-color;
            font-size: 10px;
        }
    }

    .owl-nav {
        @media (max-width:991px) {
            display: none;
        }
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        width: 50px;
        height: 50px;
        line-height: 50px;
        left: 100px;
        color: #B6B6B6;
        transition: all .3s ease-in-out;

        @media (max-width:1399px) {
            left: 20px;
        }

        @media (max-width:1199px) {
            left: 0px;
        }

        &:hover {
            border: 1px solid #BABABA;
            background: #EAE6DC;
            color: #050505;
        }
    }

    .owl-next {
        right: 50px;
        left: auto;

        @media (max-width:1399px) {
            right: -30px;
        }

        @media (max-width:1199px) {
            right: -45px;
        }
    }

    .owl-dots {
        text-align: center;
        margin-top: 20px;

        @media (min-width:992px) {
            display: none;
        }

        .owl-dot {
            margin: 0 5px;
            width: 10px;
            height: 14px;
            border-radius: 100px;
            border: 1px solid #D9D9D9;
            background: transparent;
            transition: all .9s;

            &.active {
                background: #EAE6DC;
            }
        }
    }
}

/*3.5 funfact-section */
.funfact-section {
    background: $white;
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 50px 80px 20px;

    @media (max-width:1399px) {
        padding: 50px 30px 20px;
    }

    @media (max-width:575px) {
        padding: 60px 0px;
    }

    .item {
        text-align: center;
        margin-bottom: 30px;

        h2 {
            color: #1A1729;
            font-size: 40px;
            font-weight: 700;

            @media (max-width:575px) {
                font-size: 35px;
            }
        }

        h4 {
            color: #625F71;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;

            @media (max-width:575px) {
                font-size: 18px;
            }
        }
    }
}

/*3.6 blog-section */
.blog-section {
    background: $white;
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 60px 60px 60px;

    @media (max-width:575px) {
        padding: 60px 0px;
    }

}

.blog-wrap {

    .item {
        position: relative;
        z-index: 1;
        margin-bottom: 60px;

        @media (max-width:991px) {
            margin-bottom: 30px;
        }

        .image {
            max-width: 484px;
            max-height: 332px;
            overflow: hidden;
            transition: all 1s ease-in;

            @media (max-width:1199px) {
                margin: 0 auto;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 1s ease-in;
            }
        }

        &:hover {
            .image {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .content {
            max-width: 422px;
            border-radius: 20px;
            border: 1px solid #A8A8A8;
            background: $white;
            padding: 35px 40px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width:1199px) {
                right: auto;
                top: auto;
                transform: unset;
                position: relative;
                margin: 0 auto;
                margin-top: -110px;
            }

            @media (max-width:575px) {
                padding: 20px 20px;
                margin-top: -40px;
            }

            .date {
                list-style: none;

                li {
                    color: #525252;
                    font-size: 18px;
                    font-weight: 600;
                    display: inline-block;

                    &:last-child {
                        padding-left: 20px;
                        position: relative;

                        &::before {
                            position: absolute;
                            left: 8px;
                            width: 3px;
                            height: 3px;
                            border-radius: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            content: '';
                            background: #525252;
                        }
                    }
                }
            }

            h2 {
                margin-top: 10px;
                cursor: pointer;
                transition: all .7s ease-out;

                span {
                    color: #000;
                    font-size: 33px;
                    font-weight: 700;
                    line-height: 45px;
                    transition: all .7s ease-out;

                    @media (max-width:767px) {
                        font-size: 30px;
                    }

                    @media (max-width:575px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

            }

            h4 {
                color: #A48F5B;
                font-size: 18px;
                font-weight: 700;
                line-height: 35px;
                text-decoration-line: underline;
                cursor: pointer;
                transition: all .9s ease-out;
                display: inline-block;
                margin-bottom: 0;

                &:hover {
                    color: #000;
                }
            }

            &:hover {
                h2 {
                    span {
                        color: #525252;
                    }
                }
            }
        }

        &:nth-child(2) {
            .image {
                margin-left: auto;
            }

            .content {
                right: auto;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                @media (max-width:1199px) {
                    right: auto;
                    top: auto;
                    transform: unset;
                    position: relative;
                    margin: 0 auto;
                    margin-top: -110px;
                }

                @media (max-width:575px) {
                    padding: 20px 20px;
                    margin-top: -40px;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}


/*3.7 contact-section */
.contact-section {
    background: $white;
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 80px 50px;

    @media (max-width:1399px) {
        padding: 80px 20px 50px;
    }

    @media (max-width:575px) {
        padding: 60px 0px;
    }
}

.contact-info {
    display: flex;
    justify-content: space-between;

    @media (max-width:1199px) {
        flex-wrap: wrap;
    }

    .info-item {
        width: 35%;
        display: flex;
        justify-content: left;

        @media (max-width:1199px) {
            width: 50%;
            margin-bottom: 20px;
            text-align: center;
        }

        @media (max-width:575px) {
            width: 100%;
        }

        &:nth-child(2) {
            justify-content: center;
            width: 40%;

            @media (max-width:1199px) {
                width: 50%;
            }

            @media (max-width:575px) {
                width: 100%;
            }
        }

        &:last-child {
            justify-content: end;
            width: 25%;

            @media (max-width:1199px) {
                width: 50%;
            }

            @media (max-width:575px) {
                width: 100%;
            }
        }

        @media (max-width:1399px) {
            display: block;
        }

        .icon {
            width: 50px;
            height: 50px;
            line-height: 50px;
            background: #E7E7E7;
            text-align: center;
            border-radius: 100px;
            margin-right: 10px;

            @media (max-width:1399px) {
                margin-bottom: 10px;
            }

            @media (max-width:1199px) {
                margin: 0 auto;
                margin-bottom: 10px;

            }

            .white-img {
                transition: all .3s ease;
            }

            .black-img {
                display: none;
                transition: all .3s ease;
            }
        }

        .text {


            h2 {
                color: $heading-color;
                font-family: $heading-font;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            span {
                color: #444;
                font-family: $heading-font;
                font-size: 18px;
                font-weight: 400;
            }
        }

    }
}

.contact-form-area {
    background: transparent;
    border-radius: 20px;
    padding: 40px 30px;
    margin-top: 40px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);

    @media (max-width:1199px) {
        margin-top: 20px;
    }

    @media (max-width:575px) {
        padding: 30px 15px;
    }


    .form-field {
        margin-bottom: 30px;
    }

    form input,
    form select,
    form textarea {
        background: transparent;
        width: 100%;
        height: 60px;
        border: 1px solid transparent;
        border-radius: 0px;
        box-shadow: none !important;
        padding-left: 25px;
        border-radius: 10px;
        border: 1px solid #DADADADA;
        color: $black;
        font-size: 20px;
        font-weight: 400;

        @media (max-width:767px) {
            font-size: 16px;
        }


        &:focus {
            border-color: $theme-primary-color;
            background: transparent;
        }

    }

    form textarea {
        height: 150px;
        padding-top: 15px;
        border-radius: 10px;
        border: 1px solid #DADADADA;
    }

    form {
        margin: 0 -15px;
        overflow: hidden;

        @include placeholder-style(#9d9c9c, 15px, normal);

        select {
            display: inline-block;
            color: #a9a9a9;
            cursor: pointer;
            opacity: 1;
            padding: 6px 25px;
            color: $black;
            font-size: 20px;
            appearance: auto;
            position: relative;
            width: 100%;
        }

        .submit-area {
            text-align: center;
            width: 100%;
            margin: 0;
            margin-top: 10px;
            float: none;

            @include media-query(767px) {
                margin-bottom: 0;
            }

            .submit-btn {
                border: 1px solid #DADADADA;
                border-radius: 30px;
                font-family: $base-font;
                background: $white;
                width: 250px;
                height: 60px;
                color: $heading-color;
                font-size: 20px;
                font-weight: 600;
                transition: all .3s ease-in-out;

                &:hover {
                    border: 1px solid $white;
                    background: #2A2A2A;
                    color: $white;
                }
            }
        }

        ::-webkit-input-placeholder {
            /* Edge */
            font-style: normal;
            color: $black;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-style: normal;
            color: $black;
        }

        ::placeholder {
            font-style: normal;
            color: $black;
        }
    }

    form>div {
        float: left;
        margin: 0 10px 20px;

        @include media-query(767px) {
            float: none;
        }
    }

    form .fullwidth {
        float: none;
        clear: both;

        @media (max-width:767px) {
            padding-top: 20px;
            margin-top: 20px;
        }
    }



    .nice-select {
        border-radius: 0px;
        width: 100%;
        line-height: 45px;
        color: $black;
        height: 60px;
        border-radius: 10px;
        border: 1px solid #DADADADA;
        background: transparent;
        font-size: 20px;
        font-weight: 400;

        .option {
            color: #030303;
            font-size: 18px;
        }

        &:after {
            border-bottom: 2px solid $white;
            border-right: 2px solid $white;
        }

    }

    .form-control:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem transparent;
        border-color: #8A8A8A;
        color: $black;
    }
}


/* 3.8 footer-section */

.footer-section {
    background: $white;
    border-radius: 15px;
    padding: 30px 50px;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;

    @media (max-width:1399px) {
        padding: 20px 30px;
    }

    @media (max-width:575px) {
        padding: 20px 15px;
    }

    p {
        color: #3C3C3C;
        font-family: $heading-font;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 0;
        text-align: left;

        @media (max-width:767px) {
            text-align: center;
            margin-bottom: 10px;
        }

        a {
            color: $text-color;
            font-weight: 600;
        }
    }

    ul {
        text-align: right;

        @media (max-width:767px) {
            text-align: center;
        }

        li {
            display: inline-block;

            a {
                padding: 0 5px;
                font-size: 18px;
                font-weight: 500;
                color: $black;
                display: block;
                text-align: center;
                position: relative;

                @media(max-width:575px) {
                    font-size: 16px;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    content: '';
                    background: $black;
                }
            }

            &:first-child {
                a {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}